<template>
  <b-modal
      id="edit-publisher-modal"
      ref="edit-publisher-modal"
      cancel-variant="outline-secondary"
      ok-title="Kaydet"
      @ok="handleOk"
      cancel-title="Kapat"
      centered
      title="Yeni Yayımevi Ekle"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-form-group>
          <label for="publishername">Yayımevi Adı:</label>
          <validation-provider
              #default="{ errors }"
              name="Kategori Adı"
              rules="required"
          >
            <b-form-input
                v-model="_selected.publisher_name"
                :state="errors.length > 0 ? false:null"
                id="publishername"
                type="text"
                placeholder="Kategori Adı Girin"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
              checked="true"
              name="check-button"
              switch
              inline
              v-model="isStatus"
              value="2"
              unchecked-value="4"
          >
            Durum (Çalışmıyor) {{ isStatus }}
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {BModal, BForm, BFormGroup, BFormInput, BFormCheckbox} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import {mapGetters} from "vuex";

export default {
  name: "EditPublisher",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox
  },
  data() {
    return {
      isStatus: false,
      required,
    }
  },
  computed: {
    ...mapGetters('productPublisher', ["_selected"])
  },
  methods: {
    showModal() {
      this.$refs['edit-publisher-modal'].show();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() // Prevent modal from closing
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this._selected.id) {
            alert(this._selected.id)
          } else {
            this.$store.dispatch('productPublisher/addNew', this._selected)
                .then(res => {

                })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
