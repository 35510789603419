<template>
  <section id="basic-input">
    <div class="row">
      <div class="col-md-12">
        <b-overlay
            :show="show"
            rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent>
                <div class="row">
                  <div class="col-10">
                    <b-form-group
                        label="Yayımevi Adı"
                        label-for="publishername"
                    >
                      <b-form-input
                          id="publishername"
                          placeholder="Yayımevi Adı Giriniz"
                          v-model="_seach.publisher_name"
                      />
                    </b-form-group>

                  </div>
                  <div class="col-2">
                    <b-button-group
                        size="sm"
                    >
                      <b-button
                          variant="primary"
                          @click="getAll"
                      >
                        Getir
                      </b-button>

                      <b-button
                          variant="success"
                          @click="showModal"
                      >
                        Yeni Ekle
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import {BOverlay, BForm, BButtonGroup, BButton, BFormGroup, BFormInput} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";

export default {
  name: "Search",
  components: {BOverlay, BForm, BButtonGroup, BButton, BFormGroup, BFormInput},
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters('productPublisher', ["_seach"])
  },
  methods: {
    getAll() {
      this.show = true;
      this.$store.dispatch('productPublisher/getAll')
          .then(res => {
            this.show = false;
            const count = res.length
            let variant = 'success'
            if (count < 1) variant = 'danger'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: count + ' adet Yayımevi çekildi',
                variant,
                icon: 'ArrowDownCircleIcon',
              },
            })
          })
    },
    showModal() {
      this.$store.commit('productPublisher/setSelected', {publisher_name: ""});
      this.$root.$emit('bv::show::modal', 'edit-publisher-modal', '#btnShow')
    }
  }
}
</script>

<style scoped>

</style>
