<template>
  <section id="card-text-alignment">
    <!-- Basic Inputs start -->
    <Search></Search>
    <!-- Basic Inputs end -->

    <!-- Hoverable rows start -->
    <Publishers></Publishers>
    <!-- Hoverable rows end -->

    <EditPublisher></EditPublisher>
  </section>
</template>

<script>
import Search from "@/views/product-publisher/Search";
import Publishers from "@/views/product-publisher/Publishers";
import EditPublisher from "@/views/product-publisher/EditPublisher";

export default {
  name: "PublisherHome",
  components: {
    Search, Publishers, EditPublisher
  },
}
</script>

<style scoped>

</style>
