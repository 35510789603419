<template>
  <div class="row" id="table-hover-row">
    <div class="col-12">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
            <tr>
              <th>Sıra No</th>
              <th>Adı</th>
              <th>Durum</th>
              <th>Yönet</th>
            </tr>
            </thead>
            <tbody>
            <Publisher
                v-for="publisher in publishers"
                :key="publisher.id"
                :publisher="publisher"
                :editStatus="true"
            >
            </Publisher>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Publisher from "@/views/product-publisher/Publisher";

export default {
  name: "Publishers",
  components: {Publisher},
  computed: {
    ...mapGetters('productPublisher', ["_publishers"]),
    publishers() {
      return this._publishers;
    }
  }
}
</script>

<style scoped>

</style>
